
/* AMPLIFY THEMING:  https://docs.amplify.aws/ui/customization/theming/q/framework/react */
:root {
  --amplify-primary-color: #ff6347;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-logo {
  max-width: 3em;
}

li {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.nav-link {
  padding: 0;
}

.navbar-logo:hover {
   -webkit-transform:scale(1.2);
   -moz-transform:scale(1.2);
   -o-transform:scale(1.2);
   transform:scale(1.2);
}

.navbar-logo {
    -webkit-transition:  -webkit-transform .2s ease-out;
    -moz-transition: -moz-transform .2s ease-out;
    -o-transition: -o-transform .2s ease-out;
    -ms-transition: -ms-transform .2s ease-out;
    transition: transform .2s ease-out;
}

.centered {
  display:flex;
  justify-content: center;
  align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
